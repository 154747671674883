<template>
  <div ref="root" class="sticky z-10" style="top: 80px">
    <button
      type="button"
      class="w-full bg-white shadow-md border border-brand-grey-200 p-4 flex items-center"
      @click="toggleMobileNav"
    >
      <strong class="whitespace-no-wrap mr-2">Navigate to</strong>
      <span class="truncate">{{ navItems[currentIndex]?.name }}</span>

      <Icon
        name="IconChevronDown"
        class="transform transition-all duration-200 text-black text-xl ml-auto"
        :class="showMobileNav ? 'rotate-180' : ''"
        :title="
          showMobileNav ? 'hide course navigation' : 'show course navigation'
        "
      />
    </button>

    <div
      v-if="showMobileNav"
      class="absolute w-full bg-white shadow-md border border-brand-grey-200 py-2 overflow-y-scroll"
      style="top: 55px; max-height: calc(100vh - 180px)"
    >
      <div
        ref="container"
        v-scroll-spy-link
        v-scroll-spy-active
        class="flex flex-col"
      >
        <a
          v-for="(item, idx) in navItems"
          :key="item.id"
          :href="item.id"
          :class="`hover:bg-brand-${colour}-300`"
          class="block px-4 py-2 cursor-pointer"
          @click="setIndex(idx)"
        >
          {{ item.name }}
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
interface NavItem {
  id: string
  name: string
}
interface CourseAreaMobileNavProps {
  navItems?: NavItem[]
  scrollSection?: number
  colour?: string
}

const props = withDefaults(defineProps<CourseAreaMobileNavProps>(), {
  navItems: () => [],
  scrollSection: 0,
  colour: 'blue'
})

const container = ref(null)
const currentIndex = ref(0)

const showMobileNav = ref(false)
const toggleMobileNav = () => {
  showMobileNav.value = !showMobileNav.value
}

const setIndex = (idx: number) => {
  showMobileNav.value = false
  currentIndex.value = idx
}
</script>
